@import "../../theme.scss";
.tech-course-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 5rem;
    right: 15rem;
    position: absolute;
    width: 150px;
    margin-right: 1rem;
    img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
    }

    h3 {
        color: $primary;
    }
    p {
        color: $bright;
    }
}

@media screen and (max-width: $lg) {
    .tech-course-container {
        right: 15rem;
    }
}

@media screen and (max-width: $md) {
    .tech-course-container {
        left: 2rem;
        width: 200px;
        img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
        }
    }
}

@media screen and (max-width: $sm) {
    .tech-course-container {
        position: relative;
        top: 0;
        left: 2rem;
        width: 150px;

        img {
            justify-content: center;
            width: 150px;
            height: 150px;
            border-radius: 50%;
        }
    }
}

@media screen and (max-width: $xs) {
    .tech-course-container {
        position: relative;
        top: 0;
        left: .8rem;
    }
}
