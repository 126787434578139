@use "sass:map";
@import "./theme.scss";
body {
  margin: 0;
  font-family: map.get($font-family, "inconsolata"), sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
