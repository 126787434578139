@import "../../theme.scss";
.profile {
    position: absolute;
    margin: 1rem;
    box-sizing: border-box;
    width: 200px;
    height: 200px;
    transition: transform 0.5s ease;
    background: {
        image: url("../../assets/profile.png");
        size: cover;
        repeat: no-repeat;
    }
    border: {
        color: $bright;
        width: 10px;
        radius: 50%;
        style: solid;
    }
    &.small {
        transition: all 0.5s ease;
        transform: scale(0.3) translate(0, -280px);
        transform-origin: center;
        border: {
            color: $bright;
            width: 4px;
        }
    }
}

@media screen and (max-width: $lg) {
    .profile {
        width: 100px;
        height: 100px;
        border: {
            width: 5px;
        }
        transition: transform 0.5s ease;
        &.small {
            transform-origin: center;
            transform: scale(0.6) translate(0, -50px);
            transition: transform 0.5s ease;
        }
    }
}

@media screen and (max-width: $md) {
    .profile {
        box-sizing: border-box;
        margin: 0.6rem;
        width: 50px;
        height: 50px;
        transition: transform 0.5s ease;
        border: {
            color: $bright;
            width: 4px;
        }
        &.small {
            transform: scale(0.7) translate(0, -20px);
            transition: transform 0.5 ease;
        }
    }
}
