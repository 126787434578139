@import "../../theme.scss";
.stack {
    box-sizing: border-box;
    padding-left: 1rem;
    position: absolute;
    left: 0;
    margin: 4rem 0;
    width: 300px;
    h1 {
        color: $primary;
        text-align: left;
    }
    ul {
        list-style-type: none;
        text-align: left;
        color: $bright;
        padding: 0;
        li {
            padding: 0.3rem 0;
            line-height: 1.5em;
            display: flex;
            img {
                width: 20px;
                height: 20px;
                margin-right: 1rem;
            }
        }
    }
}

@media screen and (max-width: $md) {
    .stack {
        position: relative;
        margin: 0;
    }
}
