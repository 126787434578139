@import "./theme.scss";
.container {
  text-align: center;
  width: 100%;
  height: 100dvh;

  background: $background;
  .education-header {
    float: right;
    color: $bright;
    margin: 5rem;
    text-transform: uppercase;
    font: {
      weight: 400;
      family: "Bebas Neue", sans-serif;
    }
  }
}
@media screen and (max-width: $lg) {
  .hide-container {
    display: none;
  }
}

@media screen and (min-width: $xl) {
  .hide-container {
    display: inherit;
  }
}
