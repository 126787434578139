@import "../../theme.scss";
.contact-container {
    display: flex;
    flex-direction: column;
    z-index: 10;
    width: 100%;
    float: left;
    margin: {
        top: 30rem;
    }
    h1 {
        color: $primary;
        letter-spacing: 0.2rem;
        font: {
            weight: 400;
            size: 3rem;
            family: "Bebas Neue", sans-serif;
        }
    }
    ul {
        z-index: 5;
        list-style-type: none;
        // text-align: left;
        color: $bright;
        li {
            padding: 0.5rem 0;
        }
        a {
            text-decoration: none;
            color: $primary;
            z-index: 1000;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: $md) {
    .contact-container {
        margin: {
            top: 15rem;
            left: 0;
        }
    }
}

@media screen and (max-width: $sm) {
    .contact-container {
        display: flex;
        margin: {
            top: 15rem;
            left: 0;
        }
    }
}

@media screen and (max-width: $lg) {
    .contact-container {
        margin: {
            top: 20rem;
            left: 0;
        }
    }
}
