@use "sass:map";
@import "../../theme.scss";
.history {
    box-sizing: border-box;
    width: 500px;
    margin: auto;
    display: flex;
    color: $bright;
    justify-content: center;
    background-color: $background;
    padding: 1rem 0;
}
.company {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: calc(100% - 82px);
    padding: 1rem;
    text-align: left;
    &-name {
        font-size: 2rem;
        width: 100%;
        font-family: map.get($font-family, "bebas-neue");
    }
    &-logo {
        // box-sizing: border-box;
        display: block;
        align-self: flex-start;
        width: 70px;
        height: 70px;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border: {
                radius: 45px;
            }
        }
    }
    .description {
        padding: 0.5rem 0;
        width: 100%;
        letter-spacing: 0.05rem;
        line-height: 1.5rem;
    }
    .tech-stack {
        padding: 0.5rem 0;
        font-size: 1.2rem;
        width: 100%;
        font-weight: 500;
        color: $primary;
    }
}

@media screen and (min-width: $xl) {
    .history {
        width: 800px;
    }
}

@media screen and (max-width: $md) {
    .history {
        display: inline-block;
        padding: 1rem;
        width: 100%;
        flex-direction: row;
        .company {
            width: 100%;
            padding: 1em 0;
        }
    }
}
