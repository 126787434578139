@import "../../theme.scss";
.college-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    top: 8rem;
    right: 3rem;
    position: absolute;
    width: 180px;

    h3 {
        color: $primary;
    }
    p {
        color: $bright;
    }
}
@media screen and (max-width: $lg) {
    .college-container {
        right: 2rem;

    }
}

@media screen and (max-width: $md) {
    .college-container {
        right: 2rem;
        width: 250px;
    }
}

@media screen and (max-width: $sm) {
    .college-container {
        width: 150px;
        right: 2rem;
    }
}

@media screen and (max-width: $xs) {
    .college-container {
        width: 150px;
        right: .8rem;
    }
}
