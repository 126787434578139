@use "sass:map";
$primary: #ffcc00;
$primary-light: #ffff00;
$bright: #ffffff;
$background: #253237;
$font-family: (
    "inconsolata": "Inconsolata",
    "bebas-neue": "Bebas Neue"
);

$font-weights: (
    "regular": 400,
    "semi-bold": 500,
    "bold": 600
);
// Breakpoints
$xs: 360px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1201px;
