@import "../../theme.scss";
.logo {
    background-color: $bright;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
