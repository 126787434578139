@use "sass:map";
@import "../../theme.scss";
$bebas: map.get($font-family, "bebas-neue");
.nav {
    font-family: $bebas, sans-serif;
    position: fixed;
    top: 0;
    right: 100px;
    transition: width 0.5s;
    border : {
        bottom: {
            style: solid;
            width: 4px;
            color: $primary;
        }
    }
    ul {
        position: relative;
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        transition: font-size 0.5s ease;
        font: {
            size: 3rem;
        }
        li {
            float: left;
            button {
                background: none !important;
                border: none;
                font-family: $bebas, sans-serif;
                cursor: pointer;
                padding: 0.2rem 0.5rem;
                color: $bright;
                font: {
                    size: 3rem;
                }

                &:hover {
                    transition: color 0.5s ease;
                    color: $primary-light;
                }
            }
        }
    }
    &.small {
        background-color: $background;
        display: flex;
        right: 0;
        transition: border 0.5s;
        padding: {
            right: 100px;
        }
        top: 0;
        width: 100dvw;
        transition: width 0.5s;
        justify-content: flex-end;
        ul {
            transition: font-size 0.5s ease;
            font: {
                size: 2rem;
            }
        }
    }
}

@media screen and (max-width: $md) {
    .nav {
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: flex-end;
        transition: none;

        ul {
            li {
                button {
                    font: {
                        size: 1.5rem;
                    }
                }
            }
        }
        &.small {
            transition: none;

            ul {
                button {
                    font: {
                        size: 1.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $md) {
    .nav {
        ul {
            li {
                button {
                    font: {
                        size: 1.3rem;
                    }
                }
            }
        }
        &.small {
            transition: none;

            ul {
                button {
                    font: {
                        size: 1.3rem;
                    }
                }
            }
        }
    }
}